
window.addEventListener("DOMContentLoaded", () => {
    let startDate;
    let resultStartDate;

    startDate = new Date();
    resultStartDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();

    if ($('#datarange1').length){
		 // startDate = new Date();
		 // resultStartDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDay();
        $('#datarange1').dateRangePicker({
            autoClose: true,
            singleDate : true,
			startDate: resultStartDate,
            format: 'YYYY-MM-DD',
            separator: ' - ',
            language: 'ru',
            startOfWeek: 'monday',
            singleMonth: true,
            showShortcuts: true,
            showTopbar: false,
            hoveringTooltip: false,
            getValue: function() {
                return this.innerHTML;
            },
            setValue: function(s)
            {
                this.querySelector(".daterange1__input").innerHTML = s;
                this.querySelector("input").value = s;
                this.classList.add("selected1");
            },
            selectForward: true,
            customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
            customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
            container:'#datarange1',
        });
    }

    if ($('#datarange1-1').length){
        // startDate = new Date();
		// resultStartDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDay();

        $('#datarange1-1').dateRangePicker({
            autoClose: true,
            singleDate : true,
			startDate: resultStartDate,
            format: 'YYYY-MM-DD',
            separator: ' - ',
            language: 'ru',
            startOfWeek: 'monday',
            singleMonth: true,
            showShortcuts: true,
            showTopbar: false,
            hoveringTooltip: false,
            getValue: function() {
                return this.innerHTML;
            },
            setValue: function(s)
            {
                this.querySelector(".daterange1__input").innerHTML = s;
                this.querySelector("input").value = s;
                this.classList.add("selected1");
            },
            selectForward: true,
            customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
            customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
            container:'#datarange1',
        });
    }

    if ($('#datarange2').length) {
        // startDate = new Date();
        // resultStartDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDay();
        $('#datarange2').dateRangePicker({
            autoClose: true,
            startDate: resultStartDate,
            format: 'YYYY-MM-DD',
            separator: ' - ',
            language: 'ru',
            startOfWeek: 'monday',
            singleMonth: true,
            showShortcuts: true,
            showTopbar: false,
            hoveringTooltip: false,
            getValue: function () {
                return this.innerHTML;
            },
            setValue: function (s) {
                this.querySelector(".daterange1__input").innerHTML = s;
                this.querySelector("input").value = s;
                this.classList.add("selected1");
            },
            selectForward: true,
            customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
            customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
            container: '.daterange-accordion1 .accordion1__list1',
        });
    }

	if ($('#datarange1_events').length) {
        $('#datarange1_events').dateRangePicker({
          autoClose: true,
          singleDate: false,
          startDate: resultStartDate,
          format: 'YYYY-MM-DD',
          separator: ' - ',
          language: 'ru',
          startOfWeek: 'monday',
          singleMonth: true,
          showShortcuts: true,
          showTopbar: false,
          hoveringTooltip: false,
          getValue: function getValue() {
            return this.innerHTML;
          },
          setValue: function setValue(s) {
            this.querySelector(".daterange1__input").innerHTML = s;
            this.querySelector("input").value = s;
            this.classList.add("selected1");
            $('form[name="events_filter"]').submit();
          },
          selectForward: true,
          customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
          customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
          container: '#datarange1_events'
        });
    }

    if ($('#datarange1_mob_prop').length) {
        $('#datarange1_mob_prop').dateRangePicker({
            autoClose: true,
            singleDate : true,
            startDate: resultStartDate,
            format: 'YYYY-MM-DD',
            separator: ' - ',
            language: 'ru',
            startOfWeek: 'monday',
            singleMonth: true,
            showShortcuts: true,
            showTopbar: false,
            hoveringTooltip: false,
            getValue: function() {
                return this.innerHTML;
            },
            setValue: function(s)
            {
                this.querySelector(".daterange1__input").innerHTML = s;
                this.querySelector("input").value = s;
                this.classList.add("selected1");
            },
            selectForward: true,
            customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
            customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
            container:'.mob-prop-1',
        });
    }
    if ($('#datarange1_mob_prop-1').length) {
        $('#datarange1_mob_prop-1').dateRangePicker({
            autoClose: true,
            singleDate : true,
            startDate: resultStartDate,
            format: 'YYYY-MM-DD',
            separator: ' - ',
            language: 'ru',
            startOfWeek: 'monday',
            singleMonth: true,
            showShortcuts: true,
            showTopbar: false,
            hoveringTooltip: false,
            getValue: function() {
                return this.innerHTML;
            },
            setValue: function(s)
            {
                this.querySelector(".daterange1__input").innerHTML = s;
                this.querySelector("input").value = s;
                this.classList.add("selected1");
            },
            selectForward: true,
            customArrowNextSymbol: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65764 3.75222L12.7476 9.99972L6.65764 16.2472C6.54862 16.3588 6.48758 16.5087 6.48758 16.6647C6.48758 16.8208 6.54862 16.9706 6.65764 17.0822C6.7106 17.1363 6.77381 17.1792 6.84356 17.2085C6.91332 17.2378 6.98823 17.2529 7.06389 17.2529C7.13956 17.2529 7.21447 17.2378 7.28422 17.2085C7.35398 17.1792 7.41719 17.1363 7.47014 17.0822L13.9501 10.436C14.0639 10.3193 14.1276 10.1627 14.1276 9.99972C14.1276 9.83673 14.0639 9.68019 13.9501 9.56347L7.47139 2.91722C7.4184 2.86279 7.35504 2.81953 7.28505 2.78999C7.21506 2.76045 7.13986 2.74523 7.06389 2.74523C6.98793 2.74523 6.91273 2.76045 6.84274 2.78999C6.77275 2.81953 6.70939 2.86279 6.65639 2.91722C6.54737 3.02884 6.48633 3.17869 6.48633 3.33472C6.48633 3.49076 6.54737 3.6406 6.65639 3.75222L6.65764 3.75222Z"/></svg>',
            customArrowPrevSymbol: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3453 16.2485L7.25529 10.001L13.3453 3.75351C13.4543 3.64189 13.5154 3.49204 13.5154 3.33601C13.5154 3.17998 13.4543 3.03013 13.3453 2.91851C13.2923 2.86446 13.2291 2.82153 13.1594 2.79221C13.0896 2.7629 13.0147 2.7478 12.939 2.7478C12.8634 2.7478 12.7885 2.7629 12.7187 2.79221C12.6489 2.82153 12.5857 2.86446 12.5328 2.91851L6.05279 9.56476C5.93902 9.68148 5.87535 9.83802 5.87535 10.001C5.87535 10.164 5.93902 10.3205 6.05279 10.4373L12.5315 17.0835C12.5845 17.1379 12.6479 17.1812 12.7179 17.2107C12.7879 17.2403 12.8631 17.2555 12.939 17.2555C13.015 17.2555 13.0902 17.2403 13.1602 17.2107C13.2302 17.1812 13.2935 17.1379 13.3465 17.0835C13.4556 16.9719 13.5166 16.822 13.5166 16.666C13.5166 16.51 13.4556 16.3601 13.3465 16.2485H13.3453Z"/></svg>',
            container:'.mob-prop-2',
        });
    }


    showInputPlaceholder();
    toggleDropdown();
    toggleClass(".sort1__text", ".sort1", "js-active1");
    // toggleClass(".accordion__header", ".accordion", "js-active");
    initPopups();
    togglePopup (".menu1-wrapper", ".header1__burger");
    togglePopup (".request1", ".contacts1__btn");
    togglePopup (".request1-popup", ".contacts1__btn");
    togglePopup (".request1-popup", ".contacts1__btn-request1");
    togglePopup (".photo1", ".price1__descr-img");
    togglePopup (".stop1-filter", ".stop1 .form1__title", 1000);
    togglePopup (".stop1-filter", ".price1__form .form1__title");
    togglePopup (".food1-filter", ".food1__form", 1000);
    togglePopup (".review-page1-popup", ".curent1-btn");
    togglePopup (".result1__filter", ".filter1");
    togglePopup (".where-to-go1__filter", ".where-to-go1__filter-btn .container1");
    togglePopup (".events1 .filter1", ".filter1-show");
    initClickOutside();
    toggleRadioContent();
    initSliders(".card1 .swiper");

    initAnchor();
    showDescr(".price1__descr", ".price1__list-item .card1__content-footer .btn1");
    initCounter();
    toggleFilter(".anchor1", ".gratitude1__filter");
    toggleClass(".reserve1-card__more", ".reserve1-card", "js-active1");
    toggleRating();
    toggleAvatar();


    $('.stop1 button.content1__btn').on( "click", function(){
        console.log('tet');
        initSliders(".card1 .swiper");
    });


    document.querySelector("#header__search").onfocus = function () {
         this.parentNode.parentNode.classList.add("js-active1");
    };

    document.querySelector("#header__search").onblur = function () {
		if(!this.value) {
			this.parentNode.parentNode.classList.remove("js-active1");
			$('.autocomplete_search').remove();
		}
    };

    const slider1 = new Swiper(".where1 .swiper", {
        navigation: {
            nextEl: '.where1 .swiper1__buttons-next',
            prevEl: '.where1 .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
            }
        }
    });

    const slider2 = new Swiper(".places1 .swiper", {
        navigation: {
            nextEl: '.places1 .swiper1__buttons-next',
            prevEl: '.places1 .swiper1__buttons-prev',
        },
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: ".places1 .swiper1__pagination",
            type: "bullets"
        },

    });


    const slider3 = new Swiper("#things-to-do .to-do1-swiper", {
        navigation: {
            nextEl: '#things-to-do .swiper1__buttons .swiper1__buttons-next',
            prevEl: '#things-to-do .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
    const slider4 = new Swiper("#attractions .to-do1-swiper", {
        navigation: {
            nextEl: '#attractions .swiper1__buttons .swiper1__buttons-next',
            prevEl: '#attractions .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
    const slider5 = new Swiper(".tours1-swiper", {
        navigation: {
            nextEl: '.tours1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.tours1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });

    const slider6 = new Swiper(".description1__slider .swiper", {
        navigation: {
            nextEl: '.description1__slider-next',
            prevEl: '.description1__slider-prev',
        },
        spaceBetween: 10,
        pagination: {
            el: '.description1__slider .swiper-pagination',
            type: 'bullets',
        },
    });
    const slider7 = new Swiper(".cafes1-swiper", {
        navigation: {
            nextEl: '.cafes1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.cafes1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
    const slider9 = new Swiper(".stop-block1-swiper", {
        navigation: {
            nextEl: '.stop-block1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.stop-block1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 3,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
    const slider10 = new Swiper(".trails1-swiper", {
        navigation: {
            nextEl: '.trails1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.trails1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 4,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });


    const slider11 = new Swiper(".similar1-swiper", {
        navigation: {
            nextEl: '.similar1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.similar1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 4,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
    const slider12 = new Swiper(".similar1-swiper", {
        navigation: {
            nextEl: '.similar1 .swiper1__buttons .swiper1__buttons-next',
            prevEl: '.similar1 .swiper1__buttons .swiper1__buttons-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        grabCursor: false,
        simulateTouch: true,
        slideToClickedSlide: false,
        breakpoints: {
            1000: {
                slidesPerView: 4,
                spaceBetween: 20,
                simulateTouch: false,
            }
        }
    });
});

const slider13 = new Swiper(".reviews1 .swiper", {
    navigation: {
        nextEl: '.reviews1 .swiper1__buttons .swiper1__buttons-next',
        prevEl: '.reviews1 .swiper1__buttons .swiper1__buttons-prev',
    },
    slidesPerView: 2,
    spaceBetween: 10,
    breakpoints: {
        1450: {
            slidesPerView: 3,
            spaceBetween: 20,
        }
    }
});


function showInputPlaceholder() {
    const inputs = document.querySelectorAll(".input1");

    if (inputs.length > 0) {
        inputs.forEach(input => {
            input.querySelector("input").addEventListener("keyup", () => {
                if (input.querySelector("input").value !== "") {
                    input.querySelector(".input1-placeholder").style.display = "block";
                } else {
                    input.querySelector(".input1-placeholder").style.display = "none";
                }
            });
        });
    }
}

function toggleDropdown() {
    const dropdowns = document.querySelectorAll(".dropdown1");

    if (dropdowns.length > 0) {
        dropdowns.forEach(dropdown => {
            dropdown.querySelector(".dropdown1__button").addEventListener("click", () => {
                dropdown.classList.toggle("js-active1");
            });

            if (dropdown.classList.contains("toggle")) {
                dropdown.querySelectorAll(".dropdown1__list-item").forEach((item, index) => {
                    item.addEventListener("click", () => {
                        dropdown.querySelector(".dropdown1__button-text").innerText = item.innerText;
                        if ( dropdown.querySelector(".dropdown1__select")) {
                            dropdown.querySelector(".dropdown1__select").selectedIndex = index;
                            console.log("toggle")
                        }
                        dropdown.classList.remove("js-active1");
                        dropdown.classList.add("selected1");
                    });
                });
            }
        });
    }
}

function toggleClass(triggerSelector, blockSelector, className) {
    const blocks = document.querySelectorAll(blockSelector);

    if (blocks.length > 0) {
        blocks.forEach(block => {
            block.querySelector(triggerSelector).addEventListener("click", () => {
                block.classList.toggle(className);
            });
        });
    }
}

function initPopups() {
    const popups = document.querySelectorAll('.popup1');

    if (popups.length > 0) {
        popups.forEach(popup => {
            popup.addEventListener("click", (e) => {
                if (e.target === popup || e.target.classList.contains("popup1__close")) {
                    popup.classList.remove("js-active1");
                }
            });
        });
    }
}

function togglePopup(popupSelector, triggerSelector, maxWidth=5000) {
    const trigger = document.querySelector(triggerSelector);

    if (!trigger) {
        return;
    }

    trigger.addEventListener("click", () => {
        if (maxWidth > window.innerWidth) {
            document.querySelector(popupSelector).classList.add("js-active1");
        }
    });
}

function initClickOutside() {
    $(document).click(function (e) {
        $.each($('.js-click-outside'), function (k, v) {
            if (!v.contains(e.target)) {
                $(v).removeClass('js-active1');
            }
        });
    });
}

function toggleRadioContent() {
    const btns = document.querySelectorAll(".radio1 [data-radio]");


    if (btns.length > 0) {
        const tabs = document.querySelectorAll(".radio1 [data-tab]")

        btns.forEach(btn => {
            if (btn.checked) {
                toggle(btn);
            }

            btn.addEventListener('change', toggle);
        });

        function toggle(e) {
            target = e.target || e;

            tabs.forEach(tab => {
                if (target.dataset.radio === tab.dataset.tab) {
                    tab.style.display = 'block';
                } else {
                    tab.style.display = 'none';
                }
            });
        }
    }
}

// function hoverMap() {
//     const maps = document.querySelectorAll(".map");

//     if (maps.length > 0) {
//         maps.forEach(map => {
//             const triggers = map.querySelectorAll("[data-hover]");
//             const blocks = ьфз

//             triggers.forEach(trigger => {

//             });
//         });
//     }
// }


function initSliders(sliderSelector) {
    const sliders = document.querySelectorAll(sliderSelector);

    if (sliders.length > 0) {
        sliders.forEach(slider => {
            const prev = slider.querySelector(".swiper1__buttons-prev");
            const next = slider.querySelector(".swiper1__buttons-next");
            const pagination = slider.querySelector(".swiper1__pagination");

            const swiper = new Swiper(slider, {
                spaceBetween: 10,
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                },
                pagination: {
                    el: pagination,
                    type: "bullets"
                },
                nested: true
            });
        });
    }
}

function initAnchor() {
    const anchorList = document.querySelectorAll('[data-anchor]');

    if (anchorList.length > 0) {
        window.addEventListener('scroll', toggleActive);

        anchorList.forEach(anchor => {
            anchor.addEventListener('click', (e) => {
                anchorList.forEach(anchor => {
                    anchor.classList.remove('active1');
                });

                e.target.classList.add('active1');
            });
        });

        function toggleActive(e) {
            anchorList.forEach(anchor => {
                const block = document.getElementById(anchor.dataset.anchor);

                if (block.offsetTop > $(window).scrollTop() && block.offsetTop < $(window).scrollTop() + window.screen.height/3) {
                    anchorList.forEach(anchor => {
                        anchor.classList.remove('active1');
                    });

                    document.querySelector(`[data-anchor='${anchor.dataset.anchor}']`).classList.add('active1');
                }
            });
        }
    }
}

function showDescr(descrSelector, triggerSelector) {
    const triggers = document.querySelectorAll(triggerSelector);
    const descr = document.querySelector(descrSelector);
    const triggerBlocks = document.querySelectorAll(".price1__list-item");

    if (triggers.length > 0) {
        triggers.forEach((trigger, index) => {
            trigger.addEventListener('click', () => {
                descr.classList.add('js-active1');
            });
        });
    }

    if (!descr) {
        return;
    }

    descr.querySelector(".price1__descr-close").addEventListener("click", () => {
        descr.classList.remove('js-active1');
    });
}

function initCounter() {
    const counters = document.querySelectorAll(".counter1");

    if (counters.length > 0) {
        counters.forEach(counter => {
            const minus = counter.querySelector(".counter1__controls-minus");
            const curent = counter.querySelector(".counter1__controls-curent");
            const curentInput = counter.querySelector("input");
            const plus = counter.querySelector(".counter1__controls-plus");

            let number = 0;

            minus.addEventListener("click", () => {
                if (number > 0) {
                    number -= 1;
                }
                curentInput.value = number;
                curent.innerText = number;

				        let inputAdults = $('input[name="adults"]');
						let inputKids = $('input[name="childAges"]');

						let resultText = '';

						if (inputAdults.val() == 1) {
						  resultText = inputAdults.val() + ' взрослый';
						}else if (inputAdults.val() > 1) {
						  resultText = inputAdults.val() + ' взрослых';
						}

						if (inputKids.val() == 1) {
						  resultText += ' + ' + inputKids.val() + ' ребенок';
						}else if (inputKids.val() > 1) {
						  resultText += ' + ' + inputKids.val() + ' ребенка';
						}

						$('#countPerson-text').css('color', 'black');
						$('#countPerson').text(resultText);
            });

            plus.addEventListener("click", () => {
                number += 1;
                curentInput.value = number;
                curent.innerText = number;

				        let inputAdults = $('input[name="adults"]');
						let inputKids = $('input[name="childAges"]');

						let resultText = '';

						if (inputAdults.val() == 1) {
						  resultText = inputAdults.val() + ' взрослый';
						}else if (inputAdults.val() > 1) {
						  resultText = inputAdults.val() + ' взрослых';
						}

						if (inputKids.val() == 1) {
						  resultText += ' + ' + inputKids.val() + ' ребенок';
						}else if (inputKids.val() > 1) {
						  resultText += ' + ' + inputKids.val() + ' ребенка';
						}

						$('#countPerson').css('color', 'black');
						$('#countPerson').text(resultText);
            });
        });
    }

}

function toggleFilter(filterSelector, triggerSelector) {
    const filter = document.querySelector(filterSelector);
    const trigger = document.querySelector(triggerSelector);

    if (!filter || !trigger) {
        return;
    }

    trigger.addEventListener("click", () => {
        filter.classList.add("js-active1");
    });

    filter.querySelector(".popup1__close").addEventListener("click", () => {
        filter.classList.remove("js-active1");
    });

}

function toggleRating() {
    const stars = document.querySelectorAll(".curent1__rating svg");
    const ratingArr = ['Ужасно','Плохо','Нормально','Хорошо' ,'Отлично']
    if (!stars.length) {
        return;
    }

    let index = document.querySelectorAll(".curent1__rating .js-active1").length;
    let input = document.querySelector(".curent1__rating input");
    let textRating = document.querySelector(".curent1 .textRating");
    let ratingOn = false;
    input.setAttribute('value',index);
    ratingArr.forEach ((rating, i) => {
        if (index == i+1 && ratingOn != true){
            textRating.innerHTML = rating;
            ratingOn = true;
        }
    });

    stars.forEach ((star, i) => {
        star.addEventListener("click", () => {
            index = i;
            stars.forEach((item, j) => {
                if (j <= i) {
                    item.classList.add("js-active1");
                } else {
                    item.classList.remove("js-active1");
                }
            });
            index = document.querySelectorAll(".curent1__rating .js-active1").length;
            input.setAttribute('value',index);
            let ratingOn = false;
            ratingArr.forEach ((rating, i) => {
                if (index == i+1 && ratingOn != true){
                    textRating.innerHTML = rating;
                    ratingOn = true;
                }
            });

        });

        // document.querySelector(".curent1__rating").addEventListener("mousemove", (e) => {

        //     let i = index;

        //     stars.forEach ((star, index) => {
        //         if (star === e.target) {
        //             i = index;
        //         }
        //     })


        //     stars.forEach((item, j) => {
        //         if (j <= i) {
        //             item.classList.add("js-active1");
        //         } else {
        //             item.classList.remove("js-active1");
        //         }
        //     });
        // });

        // document.querySelector(".curent1__rating").addEventListener("mouseleave", (e) => {
        //     stars.forEach((item, j) => {
        //         if (j <= index) {
        //             item.classList.add("js-active1");
        //         } else {
        //             item.classList.remove("js-active1");
        //         }
        //     });
        // });
    });
}

function toggleAvatar() {

    const input = document.querySelector("#avatar");
    let url = "";

    if (!input) {
        return;
    }

    const btnsDelete = document.querySelectorAll(".curent1__img-delete");
    const images = document.querySelectorAll(".curent1__img");

    btnsDelete.forEach(btn => {
        btn.addEventListener("click", () => {
            input.value = '';

            toggleVisibility(input, images, url);
            document.querySelector(".review-page1-popup .popup1__container-input").classList.remove("selected1");
        });
    });


    input.addEventListener("change", () => {
        const img = new FileReader();

        img.onload = function(e) {
            url = e.target.result;

            toggleVisibility(input, images, url);
            document.querySelector(".review-page1-popup .popup1__container-input").classList.add("selected1");
        };
        img.readAsDataURL(input.files[0]);
    });

    toggleVisibility(input, images, url);
}

function toggleVisibility(input, images, url) {
    if (input.files[0]) {
        images.forEach(img => {
            img.classList.add('js-active1');
            img.querySelector("img").src = url;
            document.querySelector(".popup1__container-input .btn1").innerText = "изменить фото";
            document.querySelector(".btn1.curent1-btn").innerText = "изменить фото";
        });
    } else {
        images.forEach(img => {
            img.classList.remove('js-active1');
            img.querySelector("img").src = "";
            document.querySelector(".popup1__container-input .btn1").innerText = "выбрать файл";
            document.querySelector(".btn1.curent1-btn").innerText = "загрузить аватар";
        });
    }
}
